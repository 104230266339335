import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as Constants from './../../../shared/services/common.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            // console.error('An error occurred:', error.error.message);
            
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // console.error(
            //     `Backend returned code ${error.status}, ` +
            //     `body was: ${error.error}`);
            //     console.error(error.error.status);
              
        }
        // return an observable with a user-facing error message
        return throwError(
            error.error);
    };

    constructor(private _httpClient: HttpClient) { }

    login(token: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Basic ${token}`
            })
        };
        return this._httpClient.post(`${Constants.API_URL}/api/Login`, {}, httpOptions).pipe(
            catchError(this.handleError));
    }

}
