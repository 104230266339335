
export interface ICardDetails {
    EID: string,
    cardModel: string,
    platform: string,
    UICCOwnerName: string,
    status: string,
    numberOfLoadedProfiles: string,
    isMultiProfileSupported: boolean,
    cardCapabilities: string,
    updateDate: string
}

 export const cardDetailsInitialize : ICardDetails = {
    EID :"",
    cardModel: "",
    platform: "",
    UICCOwnerName: "",
    status: "",
    numberOfLoadedProfiles: "",
    isMultiProfileSupported: false,
    cardCapabilities: "",
    updateDate: "",
}

export interface IProfileDetails {

    ICCID: string,
    profileType: string,
    multiIMSI: boolean,
    IMSIs: any,
    //to be continued
}

export interface ITransactionDetails {
    transactionId: string,
    ICCID: string,
    transactionType: string,
    status: string,
    startTime: string,
    endTime: string,
    requester :string    
}