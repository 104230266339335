import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { Router } from "@angular/router";
import * as Constants from "./shared/services/common.service";
import { UserService } from "./shared/services/user.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private readonly userService: UserService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentUserToken = localStorage.getItem("currentUserToken");
    if (currentUserToken) {
      request = request.clone({
        headers: request.headers.set(
          "Authorization",
          `Basic ${currentUserToken}`
        ),
      });
    } else {
      localStorage.removeItem("currentUserToken");
      localStorage.removeItem("loggedInTime");
      this.router.navigate(["/login"]);
      this.userService.setUserLoggedIn(false);
    }

    // return next.handle(request);
    return next.handle(request).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          return event;
        }
      }),
      catchError((error) => {
        // Handle Unauthorized Responses
        if (error.status === 401) {
        }
        return throwError(error);
      })
    );
  }

  _checkSessionExpired() {
    let loggedIn = localStorage.getItem("loggedInTime");
    if (
      Math.round(new Date().getTime() / 1000) - Number(loggedIn) >
      Constants.SESSION_TIMEOUT
    ) {
      return false;
    } else {
      return true;
    }
  }
}
