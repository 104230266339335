import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

import {
  Paginator,
  registerEntityData,
} from "../../feature/main/user/user-management/user.interface";
import * as Constants from "./../../shared/services/common.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userLoggedIn = new Subject<boolean>();

  constructor(private _httpClient: HttpClient) {
    this.userLoggedIn.next(false);
  }

  getUsers(paginator: Paginator): Observable<any> {
    return this._httpClient.get(
      `${Constants.API_URL}/api/INV.GetUsers?limit=${paginator.limit}&page=${paginator.pageNo}&sort_by=${paginator.sortColumn}&sort_order=${paginator.sortOrder}`
    );
  }
  deleteUser(userName): Observable<any> {
    return this._httpClient.delete(
      `${Constants.API_URL}/api/INV.DeleteUser/${userName}`
    );
  }
  editUser(userName: any, editedUser: any): Observable<any> {
    return this._httpClient.post(
      `${Constants.API_URL}/api/INV.EditUser/${userName}`,
      editedUser
    );
  }
  addUsers(registerEntityData): Observable<any> {
    return this._httpClient.post(
      `${Constants.API_URL}/api/INV.AddUser`,
      registerEntityData
    );
  }
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }
  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
}
