import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotificationTrackerComponent} from './notification-tracker/notification-tracker.component'
const routes: Routes = [
    { path: '',
    redirectTo: 'notification-tracker',
    pathMatch: 'full'
    },
    {path: 'notification-tracker', component:NotificationTrackerComponent},
];
export const NotificationRoutes: ModuleWithProviders = RouterModule.forChild(routes);