import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Paginator } from '../../feature/main/card/card-inventory/card-inventory.interface';
import * as Constants from './../../shared/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error.message);
  };


  constructor(private _httpClient: HttpClient) { }


  getCardDetails(cardId: string): Observable<any> {
    return this._httpClient.get(`${Constants.API_URL}/api/INV.GetCards/${cardId}`);
  }

  getCards(paginator: Paginator): Observable<any> {
    return this._httpClient.get(`${Constants.API_URL}/api/INV.Cards?limit=${paginator.limit}&page=${paginator.pageNo}&sort_by=${paginator.sortColumn}&sort_order=${paginator.sortOrder}&eid_search=${paginator.eidSearch}&profile_search=${paginator.profileSearch}`);
  }

  enableProfile(request): Observable<any> {
    return this._httpClient.post<any>(`${Constants.API_URL}/api/SM.EnableProfile`, request).pipe(
      catchError(this.handleError)
    );
  }

  deleteProfile(request): Observable<any> {
    return this._httpClient.post<any>(`${Constants.API_URL}/api/SM.DeleteProfile`, request).pipe(
      catchError(this.handleError)
    );
  }

  auditCard(request): Observable<any> {
    return this._httpClient.post(`${Constants.API_URL}/api/SM.AuditCard`, request).pipe(
      catchError(this.handleError)
    );
  }

  downloadProfile(request): Observable<any> {
    return this._httpClient.post(`${Constants.API_URL}/api/SM.DownloadProfile`, request).pipe(
      catchError(this.handleError)
    );
  }

  getTransactionHistory(cardId: string): Observable<any> {
    return this._httpClient.get(`${Constants.API_URL}/api/INV.getTransactionsForCard/${cardId}`);
  }

  updateMsisdn(request): Observable<any> {
    return this._httpClient.post(`${Constants.API_URL}/api/INV.UpdateMsisdn`, request).pipe(
      catchError(this.handleError)
    );
  }
}
