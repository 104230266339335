import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as Constants from "./../../../../shared/services/common.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  websiteLogo: string = "";
  websiteSubHeader: string = "";
  swaggerLink: string = "";

  constructor(
    private router: Router,
    private readonly userService: UserService
  ) {}

  ngOnInit() {
    this.websiteLogo = Constants.WEBSITE_LOGO;
    this.websiteSubHeader = Constants.WEBSITE_SUB_TITLE;
    this.swaggerLink = Constants.SWAGGER_UI;
  }

  logoutUser() {
    localStorage.removeItem("currentUserToken");
    localStorage.removeItem("loginStatus");
    localStorage.removeItem("loggedInTime");
    localStorage.removeItem("userLogged");
    this.userService.setUserLoggedIn(false);
    this.router.navigate(["/login"]);
  }

  gotoUserManagement() {
    this.router.navigate(["/user-management"]);
  }
}
