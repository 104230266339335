import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "./login.service";
import * as Constants from "./../../../shared/services/common.service";
import { Toaster } from "./../../../shared/services/toaster.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  message: any;
  invalidMessage: boolean = false;
  forgotPassword: string = "";
  websiteLogo: string;
  loginForm: FormGroup;
  submitted: boolean;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private toaster: Toaster,
    private userService: UserService
  ) {
    if (localStorage.getItem("currentUserToken") != null) {
      this.router.navigate(["/card-inventory"]);
      this.userService.setUserLoggedIn(true);
    } else {
      this.userService.setUserLoggedIn(false);
    }
  }

  ngOnInit(): void {
    this.forgotPassword = Constants.FORGOT_PASSWORD_EMAIL;
    this.websiteLogo = Constants.WEBSITE_LOGO;
    this.submitted = false;
    this.loginForm = new FormGroup({
      username: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
  }

  // Encoding UTF8 ⇢ base64
  b64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
      })
    );
  }

  // Decoding base64 ⇢ UTF8
  b64DecodeUnicode(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  authenticate(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      const username = this.loginForm.get("username").value;
      const password = this.loginForm.get("password").value;
      const str = `${username}:${password}`;
      let token = this.b64EncodeUnicode(str);
      this.message = false;
      localStorage.setItem("currentUserToken", token);
      localStorage.setItem("userLogged", "true");
      localStorage.setItem(
        "loggedInTime",
        Math.round(new Date().getTime() / 1000).toString()
      );
      this.userService.setUserLoggedIn(true);
      this.router.navigate(["/card-inventory"]);
      this.loginService.login(token).subscribe(
        (response: any) => {
          this.message = false;
          localStorage.setItem("currentUserToken", token);
          localStorage.setItem("userLogged", "true");
          localStorage.setItem(
            "loggedInTime",
            Math.round(new Date().getTime() / 1000).toString()
          );
          this.userService.setUserLoggedIn(true);
          this.router.navigate(["/card-inventory"]);
        },
        (error) => {
          if (error) {
            this.message = error.message;
            this.invalidMessage = true;
          }
        }
      );
    }
  }
}
