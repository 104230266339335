import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Paginator } from '../../feature/main/profile/profile-inventory/profile-inventory.interface';
import * as Constants from './../../shared/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _httpClient: HttpClient) { }

  getProfiles(): Observable<any> {
    return this._httpClient.get(`${Constants.API_URL}/api/INV.GetProfiles`);
  }

  getProfilesByICCID(iccid: string): Observable<any> {
    if (iccid) {
      return this._httpClient.get(`${Constants.API_URL}/api/INV.GetProfileByIccid/${iccid}`);
    }
    return this._httpClient.get(`${Constants.API_URL}/api/INV.GetProfiles`);
  }

  getProfileTypes(paginator: Paginator): Observable<any> {
    return this._httpClient.get(`${Constants.API_URL}/api/INV.getProfileTypes?limit=${paginator.limit}&page=${paginator.pageNo}&sort_by=${paginator.sortColumn}&sort_order=${paginator.sortOrder}&iccid_search=${paginator.iccidSearch}&imsi_seach=${paginator.imsiSearch}&profile_search=${paginator.profile_status}`);
  }

  getProfileTypesByICCID(iccid: string): Observable<any> {
    return this._httpClient.get(`${Constants.API_URL}/api/INV.getProfileTypes?iccid_search=${iccid}`);
  }

  getProfilesByProfileType(profileTypeUid: string, paginator: Paginator): Observable<any> {
    return this._httpClient.get(`${Constants.API_URL}/api/INV.getProfilesByProfileType/${profileTypeUid}?limit=${paginator.limit}&page=${paginator.pageNo}&sort_by=${paginator.sortColumn}&sort_order=${paginator.sortOrder}&iccid_search=${paginator.iccidSearch}&imsi_search=${paginator.imsiSearch}&store_status=${paginator.profile_status}&card_status=${paginator.card_status}`);
  }
}
