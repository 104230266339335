import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Paginator } from '../../feature/main/cardmodel/cardmodel-inventory/cardmodel-inventory.interface';
import * as Constants from './../../shared/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class CardmodelService {


constructor(private _httpClient: HttpClient) { }
getCardModel(paginator: Paginator): Observable<any> {
  return this._httpClient.get(`${Constants.API_URL}/api/INV.getCardModel?limit=${paginator.limit}&page=${paginator.pageNo}&sort_by=${paginator.sortColumn}&sort_order=${paginator.sortOrder}&cardtypeName_Search=${paginator.profile_status}`);
}
  
}
