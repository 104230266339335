import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransactionTrackerComponent } from './transaction-tracker.component';
// import { AuthGuard } from '../auth-guard.service';
const routes: Routes = [
    {
        path: '',
        redirectTo: 'transaction-tracker',
        pathMatch: 'full'
    },
    { path: 'transaction-tracker', component: TransactionTrackerComponent },

];

export const TransactionTrackerRoutes: ModuleWithProviders = RouterModule.forChild(routes);