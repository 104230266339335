import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardInventoryComponent } from './card-inventory/card-inventory.component';
import { CardDetailsComponent } from './card-details/card-details.component';
import { CardRoutes } from './card.routing';
import { TableModule } from 'primeng/table';
import {RadioButtonModule} from 'primeng/radiobutton';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from '../../../shared/shared.module';
import { ProfileTypeModalComponent } from './card-details/profile-type-modal/profile-type-modal.component';
import { AddProfileModalComponent } from './card-details/add-profile-modal/add-profile-modal.component';
import { OtaComponent } from './card-details/ota/ota/ota.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  imports: [
    CommonModule,
    CardRoutes,
    TableModule,
    RadioButtonModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule
  ],
  declarations: [CardInventoryComponent,
    CardDetailsComponent,
    ProfileTypeModalComponent,
    AddProfileModalComponent,
    OtaComponent
  ],
  entryComponents: [
    ProfileTypeModalComponent,
    AddProfileModalComponent
  ],
})
export class CardModule { }
