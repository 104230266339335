import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CardmodelInventoryComponent } from './cardmodel-inventory/cardmodel-inventory.component';
// import { AuthGuard } from '../auth-guard.service';
const routes: Routes = [
    {
        path: '',
        redirectTo: 'cardmodel',
        pathMatch: 'full'
    },
    { path: 'cardmodel', component: CardmodelInventoryComponent },

];

export const CardModelRoutes: ModuleWithProviders = RouterModule.forChild(routes);