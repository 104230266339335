import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Constants from './../../shared/services/common.service';
// import { Paginator } from '../../feature/main/cardmodel/cardmodel-inventory/cardmodel-inventory.interface';
import {Paginator} from  '../../feature/main/notification/notification-tracker/notification-tracker.interface';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private _httpClient: HttpClient) { }
  getNotificationDetails(paginator: Paginator): Observable<any>{
    return this._httpClient.get(`${Constants.API_URL}/api/INV.getNotificationDetails?limit=${paginator.limit}&page=${paginator.pageNo}&sort_by=${paginator.sortColumn}&sort_order=${paginator.sortOrder}&notificationtype_search=${paginator.notificationSearch}&iccid_search=${paginator.iccidSearch}&tid_search=${paginator.tidSearch}`);
  }
}
