import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class Toaster {

    constructor(private toastrService: ToastrService) { }


    success(title: any, msg: any, time : any) {
        this.toastrService.success(msg, title, {
            timeOut: time,
        });
    }

    error(title: any, msg: any, time : any) {
        this.toastrService.error(msg, title, {
            timeOut: time,
        });
    }

    warning(title: any, msg: any, time : any) {
        this.toastrService.warning(msg, title, {
            timeOut: time,
        });
    }

    info(title: any, msg: any, time : any) {
        this.toastrService.info(msg, title, {
            timeOut: time,
        });
    }
}