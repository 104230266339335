
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from './auth-guard.service';


export const routes: Routes = [
    {
        path: 'card',
        // canLoad: [AuthGuard],
        loadChildren: './feature/main/card/card.module#CardModule'
    },
    {
        path: 'auth',
        canLoad: [AuthGuard],
        loadChildren: './feature/auth/auth.module#AuthModule'
    },
    {
        path: '',
        canLoad: [AuthGuard],
        loadChildren: './feature/auth/auth.module#AuthModule'
    },
    
    // {
    //     path: '**',
    //     redirectTo: 'auth/login',
    //     pathMatch: 'full'
    // }
];

    export const AppRoutingComponent: ModuleWithProviders = RouterModule.forRoot(routes, {useHash: true});