import { Injectable } from "@angular/core";
import { CanLoad, CanActivate, Route, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  constructor(private router: Router) {}

  canLoad(route: Route): boolean {
    if (localStorage.getItem("currentUserToken")) {
      return true;
    }
    this.router.navigate(["/login"]);
    return false;
  }
  canActivate(route) {
    return true;
  }
}
