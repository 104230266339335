import { Toaster } from './../../../../../shared/services/toaster.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { CardService } from '../../../../../shared/services/card.service';
import { ProfileService } from '../../../../../shared/services/profile.service';
import * as Constants from './../../../../../shared/services/common.service';

@Component({
  selector: 'app-add-profile-modal',
  templateUrl: './add-profile-modal.component.html',
  styleUrls: ['./add-profile-modal.component.scss']
})
export class AddProfileModalComponent implements OnInit {

  title: string;
  closeBtnName: string;
  eid: string;
  isLoading: boolean;
  profiles: any = [];
  cols: any[];
  selectedProfile: any;
  iccidSearch: string = '';
  confirmationModalTitle: string;
  profileListPagination: any;

  @ViewChild('profileTable') profileTable: any;

  modalRef: BsModalRef;

  constructor(
    public bsModalRef: BsModalRef,
    private cardService: CardService,
    private profileService: ProfileService,
    private modalService: BsModalService,
    private toaster: Toaster
  ) {
    this.isLoading = false;
    this.cols = [
      { field: 'activeProfileType', header: 'Active Profile Type' },
      { field: 'numberOfAvailableProfiles', header: 'numberOfAvailableProfiles' },
      { field: 'profileSize', header: 'profileSize' },
      { field: 'multiIMSISupport', header: 'multiIMSISupport' },
    ];

  }

  ngOnInit() {
    this.getProfiles();
    this.profileListPagination = Constants.MODAL_PAGINATION;
  }

  ngOnDestroy() {
    this.iccidSearch = '';
  }

  getProfiles() {
    setTimeout(() => { this.isLoading = true; }, 0);
    this.profileService.getProfileTypesByICCID(this.iccidSearch).subscribe(response => {
      this.isLoading = false;
      this.profiles = response.profileTypes;
      this.profiles.forEach(profile => {
        profile.activeProfileType = profile.profile_type_name;
        profile.profileSize = profile.size;
        profile.multiIMSISupport = profile.multi_imsi_support;
        profile.availableProfile = profile.no_of_avaliable_profiles;
        profile.mnoName = profile.mno_name;
      });
    });
  }

  searchByICCID() {
    this.isLoading = true;
    this.profileTable.first = 0;

    this.profileService.getProfileTypesByICCID(this.iccidSearch).subscribe(response => {
      this.isLoading = false;
      this.profiles = response.profileTypes;
      this.profiles.forEach(profile => {
        profile.activeProfileType = profile.profile_type_name;
        profile.profileSize = profile.size;
        profile.multiIMSISupport = profile.multi_imsi_support;
        profile.availableProfile = profile.no_of_avaliable_profiles;
        profile.mnoName = profile.mno_name;
      });
    });
  }

  downloadProfile(confirmationModal) {

    if (this.selectedProfile) {
      this.openConfirmationModal(confirmationModal);
    } else {
      this.toaster.error(Constants.MESSAGES.missingTitle, Constants.MESSAGES.addProfileEmpty, Constants.TIME_LONG);
    }
  }

  _downloadProfile() {
    let request = {
      eid: this.eid,
      iccid: '',
      profile_type: ''
    };

    if (this.iccidSearch) {
      request['iccid'] = this.iccidSearch;
    } else {
      request['profile_type'] = this.selectedProfile.activeProfileType;
    }

    this.cardService.downloadProfile(request).subscribe(
      response => {
        if (response.status == 'Accepted') {
          this.toaster.success(Constants.MESSAGES.success, response.message, Constants.TIME_LONG);
        } else {
          this.toaster.error(Constants.MESSAGES.failure, response.message, Constants.TIME_LONG);
        }
        this.bsModalRef.hide();
      },
      error => {
        this.toaster.error(Constants.MESSAGES.failure, error, Constants.TIME_LONG);
      }
    );
  }

  openConfirmationModal(confirmationModal: TemplateRef<any>) {
    this.confirmationModalTitle = Constants.MESSAGES.downloadProfileConfirmation;
    this.modalRef = this.modalService.show(confirmationModal, { class: 'modal-sm modal-dialog-centered' });
  }

  confirmCFModal(): void {
    this._downloadProfile();
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }
}
