import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-profile-type-modal',
  templateUrl: './profile-type-modal.component.html',
  styleUrls: ['./profile-type-modal.component.scss']
})
export class ProfileTypeModalComponent implements OnInit {

  title: string;
  closeBtnName: string;
  profileData: any;
  
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
