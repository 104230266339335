import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationTrackerComponent } from './notification-tracker/notification-tracker.component';
import {NotificationRoutes} from './notification.routing';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../../../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
import { MultiSelectModule, DropdownModule, SliderModule,DialogModule } from 'primeng/primeng';
import {ButtonModule} from 'primeng/button';

@NgModule({
  imports: [
    CommonModule,
    NotificationRoutes,
    TableModule,
    SharedModule,
    DialogModule,
    ButtonModule,
    FormsModule,  HttpClientModule, BrowserAnimationsModule, MultiSelectModule, DropdownModule, SliderModule
  ],
  declarations: [NotificationTrackerComponent]
})
export class NotificationModule { }
