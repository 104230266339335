import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './user-management/user.component';
// import { AuthGuard } from '../auth-guard.service';
const routes: Routes = [
	{
		path: '', 
		redirectTo:'user-management',
		pathMatch:'full'
	},
	{ path: 'user-management', component: UserComponent}

];

export const UserRoutes: ModuleWithProviders = RouterModule.forChild(routes);