import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { OTAService } from "./../../../../../../shared/services/ota.service";
import * as Constants from "./../../../../../../shared/services/common.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-ota",
  templateUrl: "./ota.component.html",
  styleUrls: ["./ota.component.scss"],
})
export class OtaComponent implements OnInit {
  isLoading: boolean;
  requestAPI: any[];
  requestType: any[];
  myForm: FormGroup;
  timeout: any;
  iccid: string;
  eid: string;
  responseobj = {
    status: "",
    transactionStatus: "",
    logBody: "",
    transactionId: "",
  };
  selectData = [
    {
      label: "True",
      value: true,
    },
    {
      label: "False",
      value: false,
    },
  ];

  constructor(private otaService: OTAService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.requestAPI = Constants.OTA_API;
    this.route.queryParams.subscribe((params) => {
      this.iccid = params.iccid;
      this.eid = params.eid;
    });
    this.requestAPI.forEach((el) => {
      el["transactionComplete"] = false;
      el.requestParams.forEach((element) => {
        if (element.name == "eid") {
          element.value = this.eid;
        } else if (element.name == "iccid") {
          element.value = this.iccid;
        }
        element["showDesc"] = false;
      });
      el.reponseParam = this.responseobj;
    });
    this.requestType = Constants.API_TYPE;
  }

  ngOnDestroy() {
    window.clearTimeout(this.timeout);
  }

  submitForm(item, itemCount) {
    let requestObj = {};
    item.requestParams.forEach((element) => {
      requestObj[element["name"]] = element["value"];
    });
    this.callServerAPI(requestObj, item, itemCount);
  }

  callServerAPI(requestObj, item, itemCount) {
    if (item.apiMethod == "POST") {
      this.otaService.callOTAService_POST(requestObj, item.apiUrl).subscribe(
        (response) => {
          item.reponseParam = {
            ...item.reponseParam,
            status: response.message,
            transactionId: response.tid,
          };
          this.callAPIInterval(item, response);
        },
        (err) => {
          item.reponseParam = {
            ...item.reponseParam,
            status: "Failed",
            logBody: JSON.stringify(err.error),
            transactionStatus: "-",
            transactionId: "",
          };
        }
      );
    }
  }

  callAPIInterval(item, response) {
    if (!item["transactionComplete"]) {
      this.getLogStatus(item, response);
      this.getTransactionStatus(item, response);

      this.timeout = window.setTimeout(() => {
        this.callAPIInterval(item, response);
      }, Constants.OTA_API_INTERVAL);
    }
  }

  getTransactionStatus(item, response) {
    this.otaService.getTransactionDetails_GET(response.tid).subscribe(
      (response) => {
        if (
          response["status"] == "Completed" ||
          response["status"] == "Failed"
        ) {
          item["transactionComplete"] = true;
          item.reponseParam = {
            ...item.reponseParam,
            status: `Transaction ${response["status"]}`,
          };
        }
        item.reponseParam = {
          ...item.reponseParam,
          transactionStatus: response["status"],
        };
      },
      (err) => {}
    );
  }

  getLogStatus(item, response) {
    this.otaService.checkLogByTID_GET(response.tid).subscribe(
      (response) => {
        item.reponseParam = { ...item.reponseParam, logBody: response };
      },
      (err) => {
        item.reponseParam = {
          ...item.reponseParam,
          logBody: [{ time: err.error, status: err.error, action: err.error }],
        };
      }
    );
  }

  checkDisabled(name) {
    if (name == "eid" || name == "iccid") {
      return true;
    } else {
      return false;
    }
  }
}
