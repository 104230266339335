import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Paginator } from "./user.interface";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";

import { UserService } from "../../../../shared/services/user.service";
import * as Constants from "./../../../../shared/services/common.service";
import { Toaster } from "../../../../shared/services/toaster.service";
//import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Password } from "primeng/primeng";
import { first } from "rxjs/operators";
//import Swal from 'sweetalert2';

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  users: any[];
  cols: any[];
  isLoading: boolean;
  loadLazyFilter: Paginator;
  editedUser: any;
  isEditable: boolean = false;
  tempUser: string;
  modalRef: BsModalRef;
  confirmationModalTitle: string;
  paginationEnabled = false;
  registerEntity: FormGroup;
  isSubmitted: boolean = false;
  isEnabled: boolean = true;
  isConvert: number = 1;
  errorMsgCreate: string;
  successMessage: string;
  registerEntityData = {
    user_name: "",
    role: "",
    email: "",
    password: "",
    euicc_owner_id: "",
    enabled: this.isConvert,
  };
  //role_list: any = ['Service','Standard','Admin','SuperAdmin','Servlet'];

  role_list: Array<Object> = [
    { id: 1, name: "Service" },
    { id: 2, name: "Standard" },
    { id: 3, name: "Admin" },
    { id: 4, name: "SuperAdmin" },
    { id: 5, name: "Servlet" },
  ];
  constructor(
    private userService: UserService,
    private router: Router,
    private modalService: BsModalService,
    private toaster: Toaster,
    private fb: FormBuilder
  ) {
    this.cols = [
      { field: "tid", header: "User Name" },
      { field: "transaction_type", header: "User Role" },
      { field: "eid", header: "Email" },
      { field: "iccid", header: "Tenancy" },
    ];
    this.loadLazyFilter = {
      pageNo: 1,
      limit: Constants.PAGE_PAGINATION,
      eidSearch: "",
      imsiSearch: "",
      iccidSearch: "",
      sortOrder: "DESC",
      sortColumn: "",
      totalRecords: 0,
    };
  }
  changeWebsite(e) {
    console.log(e.target.value);
  }
  ngOnInit() {
    this.registerEntity = this.fb.group({
      user_name: ["", Validators.required],
      role: ["", Validators.required],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            "([0-9a-zA-Z]([-.w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-w]*[0-9a-zA-Z].)+[a-zA-Z]{2,3})$"
          ),
        ],
      ],
      password: ["", Validators.required],
      euicc_owner_id: ["", Validators.required],
      enabled: [this.isConvert, Validators.required],
    });
    //this.isEditable=true;
  }

  getUsers() {
    setTimeout(() => {
      this.isLoading = true;
    }, 0);
    this.userService.getUsers(this.loadLazyFilter).subscribe(
      (response) => {
        this.isLoading = false;
        this.users = response.users;
        if (response.totalRecords > this.loadLazyFilter.limit) {
          this.paginationEnabled = true;
        }
        this.loadLazyFilter.totalRecords = response.totalRecords;
        this.users.forEach((user) => {
          user.isEdit = false;
        });
      },
      (err) => {
        this.toaster.error(
          Constants.MESSAGES.failure,
          err.error.message,
          Constants.TIME_LONG
        );
        this.isLoading = false;
      }
    );
  }

  loadUsersLazy(event) {
    this.loadLazyFilter.limit = event.rows;
    this.loadLazyFilter.pageNo = event.first / event.rows + 1;
    this.loadLazyFilter.sortColumn = event.sortField;
    this.loadLazyFilter.sortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.getUsers();
  }

  deleteUser(user, confirmationModal) {
    if (user["isEdit"]) {
      user["isEdit"] = !user["isEdit"];
    } else {
      let token = atob(localStorage.getItem("currentUserToken"));
      if (user.userName == token.split(":")[0]) {
        // this.userService.deleteUser(userId).subscribe(response => { });
        // localStorage.removeItem('currentUserToken');
        // localStorage.removeItem("loginStatus");
        // localStorage.removeItem('loggedInTime');
        // this.router.navigate(['/login']);
        this.toaster.warning(
          Constants.MESSAGES.failure,
          Constants.MESSAGES.deleteLoggedInUser,
          Constants.TIME_LONG
        );
      } else {
        this.openConfirmationModal(confirmationModal, user);
      }
    }
  }

  editUser(user) {
    if (user["isEdit"]) {
      this.editedUser = this.users.find((usertmp) => {
        return usertmp.userName === user.userName;
      });
      this.userService
        .editUser(this.editedUser.userName, this.editedUser)
        .subscribe((response) => {
          // this.getUsers();
          user["isEdit"] = !user["isEdit"];
          let token = atob(localStorage.getItem("currentUserToken"));

          if (this.tempUser["userName"] == token.split(":")[0]) {
            let newToken = btoa(`${user.userName}:${token.split(":")[1]}`);
            localStorage.setItem("currentUserToken", newToken);
          }
          this.toaster.success(
            Constants.MESSAGES.update,
            Constants.MESSAGES.updateSuccess,
            Constants.TIME_LONG
          );
        });
    } else {
      this.tempUser = { ...user };
      user["isEdit"] = !user["isEdit"];
    }
  }

  openConfirmationModal(confirmationModal: TemplateRef<any>, user) {
    this.confirmationModalTitle = Constants.MESSAGES.deleteUserConfirmation;
    this.tempUser = user;
    this.modalRef = this.modalService.show(confirmationModal, {
      class: "modal-sm modal-dialog-centered",
    });
  }

  confirmCFModal(): void {
    this.userService
      .deleteUser(this.tempUser["userName"])
      .subscribe((response) => {
        this.getUsers();
        this.toaster.success(
          Constants.MESSAGES.delete,
          Constants.MESSAGES.deleteSuccess,
          Constants.TIME_LONG
        );
      });

    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

  openModalWithClass(template: TemplateRef<any>, entityobj?) {
    this.isSubmitted = false;
    if (entityobj) {
      this.registerEntity.patchValue({
        userName: entityobj.user_name,
        role: entityobj.role,
        email: entityobj.email,
        password: entityobj.password,
        euicc_owner_id: entityobj.euicc_owner_id,
        enabled: entityobj.enabled,
      });
      this.errorMsgCreate = "";
    }
    this.modalRef = this.modalService.show(template);
    //this.registerEntity.reset();
  }

  get regEntityForm() {
    return this.registerEntity.controls;
  }
  regEntity() {
    this.isSubmitted = true;
    this.registerEntityData.enabled = this.isConvert;
    Object.keys(this.regEntityForm).forEach((key) => {
      this.registerEntityData[key] = this.regEntityForm[key].value;
      console.log(key + "--" + this.regEntityForm[key].value);
    });
    //console.log("this.registerEntityData "+JSON.stringify(this.registerEntityData));
    setTimeout(() => {
      this.isLoading = true;
    }, 0);
    this.userService.addUsers(this.registerEntityData).subscribe(
      (data) => {
        //this.successMessage = data.status;
        //console.log('Created Succesfully'+this.successMessage);
        if (data.status == "success") {
          //this.successMessage ='User Created Succesfully';
          //alert("User Created Succesfully");
          this.getUsers();
          this.toaster.success(
            Constants.MESSAGES.success,
            data.message,
            Constants.TIME_LONG
          );
          this.modalRef.hide();
          this.registerEntity.reset();
        } else {
          //alert("User  Not Created Succesfully" +data.error.message);
          //this.errorMsgCreate = data.error.message;
          this.toaster.error(
            Constants.MESSAGES.failure,
            data.error.message,
            Constants.TIME_ADD_USER
          );
        }
      },
      (err) => {
        this.toaster.error(
          Constants.MESSAGES.failure,
          err.error.message,
          Constants.TIME_ADD_USER
        );
        this.isLoading = false;
        this.modalRef.hide();
        this.registerEntity.reset();
        //this.getUsers();
      }
    );
  }

  changeEnabled() {
    this.isEnabled = !this.isEnabled;
    console.log(this.isEnabled);
    if (!this.isEnabled) {
      this.isConvert = 0;
      console.log("Changed to False" + this.isConvert);
    }
    if (this.isEnabled) {
      this.isConvert = 1;
      console.log("Changed to True" + this.isConvert);
    }

    //this.registerEntityData.enabled= this.isConvert;
  }
  // changedValue(event){
  //   console.log(event);
  // }
}
