import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileInventoryComponent } from './profile-inventory/profile-inventory.component';
import { ProfileUsageComponent } from './profile-inventory/profile-usage/profile-usage.component';
// import { AuthGuard } from '../auth-guard.service';
const routes: Routes = [
	{
		path: '',
		redirectTo: 'profile-inventory',
		pathMatch: 'full'
	},
	{ path: 'profile-inventory', component: ProfileInventoryComponent },
	{ path: 'profile-inventory/profile-usage/:id/:typeName', component: ProfileUsageComponent },

];

export const ProfileRoutes: ModuleWithProviders = RouterModule.forChild(routes);