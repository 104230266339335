import { ChangeDetectionStrategy, Component, OnInit,EventEmitter,Output } from '@angular/core';

@Component({
  selector: 'app-ingrid-toggle',
  templateUrl: './ingrid-toggle.component.html',
  styleUrls: ['./ingrid-toggle.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
  outputs :['ChildEvent']
})
export class IngridToggleComponent implements OnInit {
  public isActive: boolean;

  @Output() sendCount : EventEmitter <boolean> = new EventEmitter<boolean>();
  public toggle(): void {
    this.isActive = !this.isActive;
    console.log("Toggle Button"+  this.isActive);
    this.sendCount.emit(this.isActive);
  }

  constructor() { }

  ngOnInit() {
  }

}
