import { Component, OnInit, TemplateRef } from '@angular/core';
import { ICardDetails, cardDetailsInitialize } from './card-details.interface';
import { CardService } from '../../../../shared/services/card.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileTypeModalComponent } from './profile-type-modal/profile-type-modal.component';
import { AddProfileModalComponent } from './add-profile-modal/add-profile-modal.component';
import * as Constants from './../../../../shared/services/common.service';
import { Toaster } from '../../../../shared/services/toaster.service';
import { OTAService } from './../../../../shared/services/ota.service';


@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsComponent implements OnInit {

  cardDetails: ICardDetails;
  profileDetails: any;
  transactionDetails: any = [];
  isLoading: boolean;
  isLoadingTransactions: boolean;
  cols: any[];
  cardId: string;
  profileDetailsModal: BsModalRef;
  addProfileModal: BsModalRef;
  transactionHistPagination: number;
  profileListPagination: number;
  showOTA: boolean;
  modalRef: BsModalRef;
  confirmationModalTitle: string;
  confirmationModalType: string;
  confirmationModalValue: any;
  updateMSISDNValue: string;

  constructor(
    private cardService: CardService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router,
    private toaster: Toaster,
    private otaService: OTAService
  ) {
    this.profileDetails = [];
    this.cardDetails = cardDetailsInitialize;
    this.isLoading = false;
    this.isLoadingTransactions = false;
    this.showOTA = Constants.ENABLE_OTA_FOR_DISABLED;
    this.cols = [
      { field: 'iccid', header: 'ICCID' },
      { field: 'activeProfileType', header: 'Active Profile Type' },
      { field: 'multiIMSISupport', header: 'multiIMSISupport' },
      { field: 'imsi', header: 'imsi' },
      { field: 'status', header: 'Status' }
    ];
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.cardId = params['id'];
    });
    this.getCardDetails();
    this.getTransactionHistory();
    this.transactionHistPagination = Constants.PAGE_PAGINATION;
    this.profileListPagination = Constants.MODAL_PAGINATION;
  }

  getCardDetails() {
    this.isLoading = true;
    this.cardService.getCardDetails(this.cardId).subscribe(response => {
      this.isLoading = false;
      this.cardDetails.EID = response.eid;
      this.cardDetails.cardModel = response.card_model;
      this.cardDetails.status = response.eis_status;
      this.cardDetails.platform = response.platform;
      this.cardDetails.isMultiProfileSupported = response.multi_profile_support;
      this.cardDetails.numberOfLoadedProfiles = response.profiles.length;
      this.cardDetails.UICCOwnerName = response.euicc_owner;
      this.cardDetails.cardCapabilities = response.card_capabilities;

      if (response.update_time && this.getFormattedDate(response.update_time)) {
        this.cardDetails.updateDate = response.update_time;
      } else {
        this.cardDetails.updateDate = '-';
      }
      this.profileDetails = response.profiles;

      let profileDetails = Array.from(this.profileDetails);
      profileDetails.forEach((profile: any) => {
        profile.activeProfileType = profile.profile_type.profile_type_name;
        profile.status = profile.active_status;
        profile.multiIMSISupport = profile.profile_type.multi_imsi_support;
        profile.imsi = profile.imsi ? profile.imsis[0].imsi : "-";
        // if (profile.profile_type.multi_imsi_support) {
        let imsiArray = profile.imsis;
        imsiArray.sort((a, b) => {
          return a.imsi_index - b.imsi_index
        });
        profile.imsi = imsiArray.map((imsi) => {
          let o = {};
          o['value'] = imsi.imsi;
          o['index'] = imsi.imsi_index;
          return o;
        });
        // }
      });
    }, err => {
    });
  }

  enableProfile(iccid) {
    let request = {
      iccid: iccid,
      eid: this.cardId
    };
    this.cardService.enableProfile(request).subscribe(
      response => {
        if (response.status == 'Accepted') {
          this.getCardDetails();
          this.toaster.success(Constants.MESSAGES.success, response.message, Constants.TIME_LONG);
        } else {
          this.toaster.error(Constants.MESSAGES.failure, response.message, Constants.TIME_LONG);
        }
      }, error => {
        this.toaster.error(Constants.MESSAGES.failure, error, Constants.TIME_LONG);
      }
    );
  }

  deleteProfile(iccid) {
    let request = {
      iccid: iccid,
      eid: this.cardId
    };
    this.cardService.deleteProfile(request).subscribe(
      response => {
        if (response.status == 'Accepted') {
          this.getCardDetails();
          this.toaster.success(Constants.MESSAGES.success, response.message, Constants.TIME_LONG);
        } else {
          this.toaster.error(Constants.MESSAGES.failure, response.message, Constants.TIME_LONG);
        }
      }, error => {
        this.toaster.error(Constants.MESSAGES.failure, error, Constants.TIME_LONG);
      }
    );
  }

  auditCard() {
    let request = {
      eid: this.cardId
    };
    this.cardService.auditCard(request).subscribe(
      response => {
        this.getCardDetails();
        this.toaster.success(Constants.MESSAGES.success, Constants.MESSAGES.auditSuccess, Constants.TIME_LONG);
      }
    );
  }

  addNewProfile() {
    const initialState = {
      eid: this.cardId,
      title: 'Select Profile for Download'
    };
    this.addProfileModal = this.modalService.show(AddProfileModalComponent, { initialState, class: 'modal-lg' });
    this.addProfileModal.content.closeBtnName = 'Close';
  }

  openProfileDetailsModal(profileType) {

    const initialState = {
      profileData: profileType,
      title: 'Profile Details'
    };
    // profileType['plmn_files'] = profileType['plmn_files'].replace(",", ", ");

    this.profileDetailsModal = this.modalService.show(ProfileTypeModalComponent, { initialState });
    this.profileDetailsModal.content.closeBtnName = 'Close';
  }

  getTransactionHistory() {
    this.isLoadingTransactions = true;
    this.cardService.getTransactionHistory(this.cardId).subscribe(response => {
      this.isLoadingTransactions = false;
      this.transactionDetails = response;
      this.transactionDetails.forEach((transaction: any) => {

        if (this.getFormattedDate(transaction.dateModified)) {
          transaction.dateModified = transaction.dateModified;
        } else {
          transaction.dateModified = '-';
        }
        if (this.getFormattedDate(transaction.dateCreated)) {
          transaction.dateCreated = transaction.dateCreated;
        } else {
          transaction.dateCreated = '-';
        }
        transaction['logStatus'] = [];
      });
    });
  }

  gotoOta(data) {
    this.router.navigate(['/card-inventory/ota'], { queryParams: { eid: this.cardId, iccid: data.iccid } });
  }

  updateMsisdn() {

    let request = {
      iccid: this.confirmationModalValue['iccid'],
      msisdn: this.updateMSISDNValue
    };
    this.cardService.updateMsisdn(request).subscribe(
      response => {
        this.getCardDetails();
        this.updateMSISDNValue = '';
        this.modalRef.hide();
        this.toaster.success(Constants.MESSAGES.success, response.message, Constants.TIME_LONG);
      }, error => {
        this.updateMSISDNValue = '';
        this.toaster.error(Constants.MESSAGES.failure, error, Constants.TIME_LONG);
        this.modalRef.hide();
      }
    );
  }

  openConfirmationModal(confirmationModal: TemplateRef<any>, type: string, value) {
    if (type == 'deleteProfile') {
      this.confirmationModalType = 'deleteProfile';
      this.confirmationModalTitle = Constants.MESSAGES.deleteProfileConfirmation;
      this.confirmationModalValue = value;
    } else if (type == 'enableProfile') {
      this.confirmationModalType = 'enableProfile';
      this.confirmationModalTitle = Constants.MESSAGES.enableProfileConfirmation;
      this.confirmationModalValue = value;
    } else if (type == 'auditCard') {
      this.confirmationModalType = 'auditCard';
      this.confirmationModalTitle = Constants.MESSAGES.auditCardConfirmation;
      this.confirmationModalValue = '';
    }
    this.modalRef = this.modalService.show(confirmationModal, { class: 'modal-sm modal-dialog-centered' });
  }

  confirmCFModal(): void {

    if (this.confirmationModalType == 'deleteProfile') {
      this.deleteProfile(this.confirmationModalValue);
    } else if (this.confirmationModalType == 'enableProfile') {
      this.enableProfile(this.confirmationModalValue);
    } else if (this.confirmationModalType == 'auditCard') {
      this.auditCard();
    }
    this.modalRef.hide();
  }

  decline(): void {
    this.updateMSISDNValue = '';
    this.modalRef.hide();
  }

  openUpdateMSISDNModal(updateMSISDNModal: TemplateRef<any>, value) {

    this.confirmationModalTitle = Constants.MESSAGES.enterMSISDN;
    this.confirmationModalValue = value;
    this.modalRef = this.modalService.show(updateMSISDNModal, { class: 'modal-sm modal-dialog-centered' });
  }

  confirmUpdateMSISN(): void {
    if (this.updateMSISDNValue != '') {
      this.updateMsisdn();
    } else {
      this.toaster.error(Constants.MESSAGES.failure, Constants.MESSAGES.updateMSISDNEmpty, Constants.TIME_LONG);
    }
  }

  loadTransactionLogStatus(event, data) {

    this.otaService.checkLogByTID_GET(data.tid).subscribe(
      response => {
        data.logStatus = response;
        if (data.logStatus) {
          data.logStatus.forEach(log => {

            if (this.getFormattedDate(log.time)) {
              log.time = log.time;
            } else {
              log.time = '-';
            }
          });
        }
        data.isLogLoading = false;
      },
      (err) => {
        data.isLogLoading = false;
        // item.reponseParam = { ...item.reponseParam, logBody: [{ 'time': err.error, 'status': err.error, 'action': err.error }] }
      }
    )
  }

  getFormattedDate(date) {

    let d = new Date(date);

    if (!isNaN(d.getTime())) {
      return true;
    } else {
      return false;
    }
  }

}
