import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LazyLoadEvent, DataTable } from "primeng/primeng";
import { CardmodelService } from "../../../../shared/services/cardmodel.service";
import { ConfirmationService, Message, SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { Paginator } from "./cardmodel-inventory.interface";
import * as Constants from "./../../../../shared/services/common.service";
import { ButtonModule } from "primeng/button";
import {
  MultiSelectModule,
  DropdownModule,
  SliderModule,
  DialogModule,
} from "primeng/primeng";

@Component({
  selector: "app-cardmodel-inventory",
  templateUrl: "./cardmodel-inventory.component.html",
  styleUrls: ["./cardmodel-inventory.component.scss"],
})
export class CardmodelInventoryComponent implements OnInit {
  cardmodels: any[];
  cols: any[];
  loadLazyFilter: Paginator;
  isLoading: boolean;
  paginationEnabled = true;
  cardmodelInventoryHistory: any;
  displayDialog: boolean;
  constructor(
    private router: Router,
    private cardmodelService: CardmodelService
  ) {
    this.isLoading = false;
    this.cols = [
      { field: "cardmodelname", header: "Card Model Name" },
      { field: "numberofcards", header: "Number Of Cards" },
      { field: "httpsupport", header: "HTTPS Support" },
      { field: "smssupport", header: "SMS Support" },
      { field: "cardmodeltype", header: "Card Model Type" },
    ];
    this.loadLazyFilter = {
      pageNo: 1,
      limit: Constants.PAGE_PAGINATION,
      sortOrder: "ASC",
      sortColumn: "card_model_name",
      totalRecords: 0,
      profile_status: "",
    };
    this.cardmodelInventoryHistory = Constants.PAGE_PAGINATION;
  }

  ngOnInit() {}
  getCardModule() {
    setTimeout(() => {
      this.isLoading = true;
    }, 0);
    this.cardmodelService
      .getCardModel(this.loadLazyFilter)
      .subscribe((response) => {
        this.isLoading = false;
        this.cardmodels = response.cardModel;
        this.loadLazyFilter.totalRecords = response.totalRecords;
        if (response.totalRecords > this.loadLazyFilter.limit) {
          this.paginationEnabled = true;
        }

        // console.log(err)
      });
  }
  loadCardModelLazy(event) {
    this.loadLazyFilter.limit = event.rows;
    this.loadLazyFilter.pageNo = event.first / event.rows + 1;
    this.loadLazyFilter.sortColumn = event.sortField;
    this.loadLazyFilter.sortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.getCardModule();
  }
  search(event) {
    setTimeout(() => {
      this.isLoading = true;
    }, 0);
    this.loadLazyFilter.profile_status = event.target.value;
    this.loadLazyFilter.pageNo = 1;
    this.cardmodelService
      .getCardModel(this.loadLazyFilter)
      .subscribe((response) => {
        this.isLoading = false;
        this.loadLazyFilter.totalRecords = response.totalRecords;
        this.cardmodels = response.cardModel;
      });
  }
}
