import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../../shared/services/profile.service';
import { Paginator } from './profile-inventory.interface';
import * as Constants from './../../../../shared/services/common.service';

@Component({
  selector: 'app-profile-inventory',
  templateUrl: './profile-inventory.component.html',
  styleUrls: ['./profile-inventory.component.scss']
})
export class ProfileInventoryComponent implements OnInit {

  profiles: any[];
  isLoading: boolean;
  cols: any[];
  loadLazyFilter: Paginator;
  profileInventoryHistory: any;
  paginationEnabled = true

  constructor(private profileService: ProfileService) {
    this.isLoading = false;
    this.cols = [
      { field: 'activeProfileType', header: 'Active Profile Type' },
      { field: 'numberOfAvailableProfiles', header: 'numberOfAvailableProfiles' },
      { field: 'profileSize', header: 'profileSize' },
      { field: 'multiIMSISupport', header: 'multiIMSISupport' },
    ];
    this.loadLazyFilter = {
      pageNo: 1,
      limit: Constants.PAGE_PAGINATION,
      sortOrder: "ASC",
      sortColumn: "profiletype_name",
      totalRecords: 0,
      iccidSearch: "",
      imsiSearch: "",
      profile_status: "",
      card_status: ""
    };
    this.profileInventoryHistory = Constants.PAGE_PAGINATION;
  }

  ngOnInit() {
  }

  getProfiles() {
    setTimeout(() => { this.isLoading = true; }, 0);
    this.profileService.getProfileTypes(this.loadLazyFilter).subscribe(response => {
      this.isLoading = false;
      this.loadLazyFilter.totalRecords = response.totalRecords;
      if (response.totalRecords > this.loadLazyFilter.limit) {
        this.paginationEnabled = true;
      }
      this.profiles = response.profileTypes;
    });
  }

  loadProfilesLazy(event) {
    this.loadLazyFilter.limit = event.rows;
    this.loadLazyFilter.pageNo = event.first / event.rows + 1;
    this.loadLazyFilter.sortColumn = event.sortField;
    this.loadLazyFilter.sortOrder =
      event.sortOrder === 1 ? "ASC" : "DESC";
    this.getProfiles();
  }

  search(event) {

    setTimeout(() => { this.isLoading = true; }, 0);
    this.loadLazyFilter.profile_status = event.target.value;
    this.loadLazyFilter.pageNo = 1;
    this.profileService.getProfileTypes(this.loadLazyFilter).subscribe(response => {
      this.isLoading = false;
      this.loadLazyFilter.totalRecords = response.totalRecords;
      this.profiles = response.profileTypes;
    });
  }
}
