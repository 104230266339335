import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionTrackerComponent } from './transaction-tracker.component';
import { TransactionTrackerRoutes } from './transaction-tracker.routing';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../../../../shared/shared.module';
import { IngridToggleComponent } from '../../ingrid-toggle/ingrid-toggle.component';

@NgModule({
  imports: [
    CommonModule,
    TransactionTrackerRoutes,
    TableModule,
    SharedModule
  ],
  declarations: [TransactionTrackerComponent, IngridToggleComponent]
})
export class TransactionTrackerModule { }
