import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CardModule } from "./card/card.module";
import { ProfileModule } from "./profile/profile.module";
import { TransactionTrackerModule } from "./transaction-tracker/transaction-tracker/transaction-tracker.module";
import { UserModule } from "./user/user.module";
import { CardmodelModule } from "./cardmodel/cardmodel.module";
import { NotificationModule } from "./notification/notification.module";
import { IdleTimeoutComponent } from "./idle-timeout/idle-timeout.component";
import { MomentModule } from "angular2-moment";
import { ModalModule } from "ngx-bootstrap/modal";
@NgModule({
  imports: [
    CommonModule,
    CardModule,
    ProfileModule,
    TransactionTrackerModule,
    UserModule,
    CardmodelModule,
    NotificationModule,
    MomentModule,
    ModalModule,
  ],
  declarations: [IdleTimeoutComponent],
  exports: [IdleTimeoutComponent],
})
export class MainModule {}
