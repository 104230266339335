import { Component, OnInit, ViewChild } from '@angular/core';
import { CardService } from '../../../../shared/services/card.service';
import { Paginator } from './card-inventory.interface';
import { Router } from '@angular/router';
import * as Constants from './../../../../shared/services/common.service';

@Component({
  selector: 'app-card-inventory',
  templateUrl: './card-inventory.component.html',
  styleUrls: ['./card-inventory.component.scss']
})

export class CardInventoryComponent implements OnInit {

  cards: any = [];
  cols: any[];
  isLoading: boolean;
  loadLazyFilter: Paginator;
  eidSearchValue: any;
  profileSearchValue: any;
  cardsInventoryPagination: any;
  paginationEnabled = true



  @ViewChild('cardTable') cardTable: any;

  constructor(private cardService: CardService, private router: Router) {
    this.isLoading = false;
    this.cardsInventoryPagination = Constants.PAGE_PAGINATION;
    this.cols = [
      { field: 'eid', header: 'EID' },
      { field: 'card_model', header: 'Card Model' },
      { field: 'euicc_owner', header: 'Owner' },
      { field: 'eis_status', header: 'Status' },
      { field: 'number', header: 'Number of Loaded Profiles' },
      { field: 'activeProfileType', header: 'Active Profile Type' },
      { field: 'MSISDN', header: 'Active MSISDN' },
      { field: 'os_card_version', header: 'OS Card Version' },
      { field: 'date', header: 'Update Date' }
    ];
    this.loadLazyFilter = {
      pageNo: 1,
      limit: this.cardsInventoryPagination,
      eidSearch: "",
      profileSearch: "",
      sortOrder: "ASC",
      sortColumn: "eid",
      totalRecords: 0
    };
  }

  ngOnInit() {

    let token = localStorage.getItem('currentUserToken');
    if (token && token != null) {
      this.getCards();
    } else {
      this.router.navigate(['/login']);
    }
  }

  getCards() {

    this.isLoading = true;
    this.cardService.getCards(this.loadLazyFilter).subscribe(response => {
      this.isLoading = false;
      this.cards = response.eis;
      this.loadLazyFilter.totalRecords = response.totalRecords;
      if (response.totalRecords > this.loadLazyFilter.limit) {
        this.paginationEnabled = true;
      }
      this.cards.forEach(card => {
        card.number = card.profiles.length;
        card.date = card.update_time;
        card.showRowExpansion = false;
        const profilesArray = Array.from(card.profiles);
        profilesArray.forEach((profile: any) => {
          if (profile.active_status == "Enabled") {
            card.activeProfileType = profile.profile_type.profile_type_name;
            card.MSISDN = profile.msisdn;
            card.capabilities = profile.profile_type.capabilities ? profile.profile_type.capabilities : "-";
            card.fplmn_files = profile.profile_type.fplmn_files;
            card.mno_name = profile.profile_type.mno_name;
            card.multi_imsi_support = profile.profile_type.multi_imsi_support;
            card.plmn_files = profile.profile_type.plmn_files;
            card.size = profile.profile_type.size;
            card.showRowExpansion = true;
          }
        });
      });
    },err => {
      // console.log(err)
    });
  }

  loadCardsLazy(event) {
    this.loadLazyFilter.limit = event.rows;
    this.loadLazyFilter.pageNo = event.first / event.rows + 1;
    this.loadLazyFilter.sortColumn = event.sortField;
    this.loadLazyFilter.sortOrder =
      event.sortOrder === 1 ? "ASC" : "DESC";
    let token = localStorage.getItem('currentUserToken');
    if (token && token != null) {
      this.getCards();
    } else {
      this.router.navigate(['/login']);
    }
  }

  search() {
    setTimeout(() => {
      // if (field === "eid") {
      //   this.loadLazyFilter.eidSearch = event.target.value;
      //   this.loadLazyFilter.profileSearch = "";

      // } else if (field === "activeProfileType") {
      //   this.loadLazyFilter.profileSearch = event.target.value;
      //   this.loadLazyFilter.eidSearch = "";
      // }else {
      //   this.loadLazyFilter.profileSearch = event.target.value;
      // }



      this.loadLazyFilter.eidSearch = this.eidSearchValue ? this.eidSearchValue : "";
      this.loadLazyFilter.profileSearch = this.profileSearchValue ? this.profileSearchValue : "";

      this.cardTable.first = 0;
      this.loadLazyFilter.pageNo = 1;
      this.getCards();
    }, 1000);
  }
}
