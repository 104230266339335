import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as Constants from './../../shared/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class OTAService {

  constructor(private _httpClient: HttpClient) { }

  callOTAService_POST(request, url): Observable<any> {
    return this._httpClient.post(`${Constants.API_URL}` + url, request);
  }

  checkLogByTID_GET(tid: string) {
    return this._httpClient.get(`${Constants.API_URL}/api/INV.getLogsDetailsForTid?tid=${tid}&limit=${Constants.OTA_LOG_LIMIT}`);
  }

  getTransactionDetails_GET(tid: string) {
    return this._httpClient.get(`${Constants.API_URL}/api/INV.getTransactionDetails/${tid}`);
  }

}
