import { Component, OnInit ,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { LazyLoadEvent, DataTable } from 'primeng/primeng';
import {  NotificationService } from '../../../../shared/services/notification.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
// import {Paginator} from './cardmodel-inventory.interface';
import {Paginator} from './notification-tracker.interface';
import * as Constants from './../../../../shared/services/common.service';
import {ButtonModule} from 'primeng/button';
import { MultiSelectModule, DropdownModule, SliderModule,DialogModule } from 'primeng/primeng';
import { ConfirmationService, Message, SelectItem } from "primeng/api";
export interface NotificationData {
  notificationNumber?:number;
  tid?:string;
  iccid?:string;
  type?:string;
  time?:string;
  interpreted_content?:string;
  content?:string;
  
}

@Component({
  selector: 'app-notification-tracker',
  templateUrl: './notification-tracker.component.html',
  styleUrls: ['./notification-tracker.component.scss']
})
export class NotificationTrackerComponent implements OnInit {
  notification : any[];
  cols: any[];
  loadLazyFilter: Paginator;
  isLoading: boolean;
  paginationEnabled = true;
  notificationInventoryHistory: any;
  notificationSearchValue: any;
  iccidSearchValue: any;
  tidSearchValue: any;
  displayDialog: boolean;
   checkNotificationData:any;
   Tid: any;
   iccid:any;
   createdtime:any;
   interpretedcontent:any;
  @ViewChild('notificationTable') notificationTable: any;

  constructor(private router: Router,private notificationService: NotificationService ) {
    this.isLoading = false;
    this.cols = [
      { field: 'notificationnumber', header: 'Notification Number' },
      { field: 'notificationtype', header: 'Notification Type' },
      { field: 'iccid', header: 'ICCID'},
      { field: 'tid', header: 'Tid' },
      { field: 'createdtime', header: 'Created Time' },
      { field: 'source', header: 'Source' },
    ];
    this.loadLazyFilter = {
      pageNo: 1,
      limit: Constants.PAGE_PAGINATION,
      sortOrder: "ASC",
      sortColumn: "create_time",
      totalRecords: 0,
      notificationSearch: "",
      iccidSearch: "",
        tidSearch: "",
     
    };
    this.notificationInventoryHistory = Constants.PAGE_PAGINATION;
  }
   
 
  ngOnInit() {
  }
  getNotificationData(){
    setTimeout(() => { this.isLoading = true; }, 0);
    this.notificationService.getNotificationDetails(this.loadLazyFilter).subscribe(response=> {
      //  console.log("Response-------"+JSON.stringify(data));
      // console.log("check-------"+JSON.stringify(data.limit));
      // console.log("Start------"+ data.inboundNotification[0].type);
      // console.log("Start------"+ data.inboundNotification[0].tid);
      this.isLoading = false
      this.loadLazyFilter.totalRecords = response.totalRecords;
      if (response.totalRecords > this.loadLazyFilter.limit) {
        this.paginationEnabled = true;
      }
this.notification = response.inboundNotification;
    });
  }
  
  loadNotificationLazy(event) {
    this.loadLazyFilter.limit = event.rows;
  this.loadLazyFilter.pageNo = event.first / event.rows + 1;
  this.loadLazyFilter.sortColumn = event.sortField;
  //console.log("sortField---"+event.sortField);
  this.loadLazyFilter.sortOrder =
    event.sortOrder === 1 ? "ASC" : "DESC";
    this.getNotificationData();
  }
  search() {
    setTimeout(() => {
      this.loadLazyFilter.notificationSearch = this.notificationSearchValue ? this.notificationSearchValue : "";
      this.loadLazyFilter.iccidSearch = this.iccidSearchValue ? this.iccidSearchValue : "";
    this.loadLazyFilter.tidSearch = this.tidSearchValue ? this.tidSearchValue : "";

      this.notificationTable.first = 0;
      this.loadLazyFilter.pageNo = 1;
      this.getNotificationData();
    }, 1000);
  }
  openPopup(rowData){
    this.checkNotificationData=rowData.type;
    this.Tid=rowData.tid;
    this.iccid=rowData.iccid;
    this.createdtime=rowData.time;
    this.interpretedcontent=JSON.stringify(rowData.interpreted_content);
    this.displayDialog= true;
    
    }
  
}