import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from '../../../../../shared/services/profile.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Paginator } from '../profile-inventory.interface';
import * as Constants from './../../../../../shared/services/common.service';

@Component({
  selector: 'app-profile-usage',
  templateUrl: './profile-usage.component.html',
  styleUrls: ['./profile-usage.component.scss']
})
export class ProfileUsageComponent implements OnInit {

  profileTypeUid: string;
  isLoading: boolean;
  cols: any[];
  loadLazyFilter: Paginator;
  profiles: any;
  iccidSearchValue: any;
  imsiSearchValue: any;
  profileStatusSearchValue: any;
  cardStatusSearchValue: any;
  profileTypeName: string;

  @ViewChild('profileUsageTable') profileTable: any;


  constructor(private profileService: ProfileService,
    private activatedRoute: ActivatedRoute) {
    this.isLoading = false;
    this.loadLazyFilter = {
      pageNo: 1,
      limit: Constants.PAGE_PAGINATION,
      sortOrder: "ASC",
      sortColumn: "",
      totalRecords: 0,
      iccidSearch: "",
      imsiSearch: "",
      profile_status: "",
      card_status: ""
    };
    this.cols = [
      { field: 'iccid', header: 'ICCID' },
      { field: 'imsi', header: 'numberOfAvailableProfiles' },
      { field: 'profile_status', header: 'profileSize' },
      { field: 'card_status', header: 'multiIMSISupport' },
      { field: 'see profile', header: 'See Profile' },
    ];
  }

  ngOnInit() {

    this.activatedRoute.params
      .subscribe(params => {
        this.profileTypeUid = params['id'];
        this.profileTypeName = params['typeName'];
      });
  }


  getProfilesByProfileType() {
    setTimeout(() => { this.isLoading = true; }, 0);
    this.profileService.getProfilesByProfileType(this.profileTypeUid, this.loadLazyFilter).subscribe(response => {
      this.isLoading = false;
      this.profiles = response.profiles;
      this.profiles.forEach(element => {

        if (element.update_time && this.getFormattedDate(element.update_time)) {
          element.update_time = element.update_time;
        } else {
          element.update_time = '-';
        }
        // element.imsi = element.imsis.map((imsi) => {
        //   return `${imsi.imsi} Index: ${imsi.imsi_index}`
        // });
        let imsiArray = element.imsis;
        imsiArray.sort((a, b) => {
          return a.imsi_index - b.imsi_index
        });
        element.imsi = imsiArray.map((imsi) => {
          let o = {};
          o['value'] = imsi.imsi;
          o['index'] = imsi.imsi_index;
          return o;
        });
      });
      // this.profiles.imsi = this.profiles.imsi ? this.profiles.imsis[0].imsi : "-";
      // if (this.profiles.imsis.length > 1) {
      //   let imsiArray = this.profiles.imsis;
      // imsiArray.sort((a, b) => {
      //   return a.imsi_index - b.imsi_index
      // });
      // this.profiles.imsi = this.profiles.imsis.map((imsi) => {
      //   return `${imsi.imsi} Index: ${imsi.imsi_index}`
      // });
      // }
    });
  }

  loadProfilesLazy(event) {
    this.loadLazyFilter.limit = event.rows;
    this.loadLazyFilter.pageNo = event.first / event.rows + 1;
    this.loadLazyFilter.sortColumn = event.sortField;
    this.loadLazyFilter.sortOrder =
      event.sortOrder === 1 ? "DESC" : "ASC";
    this.getProfilesByProfileType();
  }

  search(event, field) {
    setTimeout(() => {
      // if (field === "iccid") {
      //   this.loadLazyFilter.imsiSearch = event.target.value;


      // } else if(field === "imsi"){
      //   this.loadLazyFilter.iccidSearch = event.target.value;

      // }else if(field === "profile_status"){
      //   this.loadLazyFilter.profile_status = event.target.value;
      // }else{
      //   this.loadLazyFilter.card_status = event.target.value;
      // }

      this.loadLazyFilter.iccidSearch = this.iccidSearchValue ? this.iccidSearchValue : "";
      this.loadLazyFilter.imsiSearch = this.imsiSearchValue ? this.imsiSearchValue : "";
      this.loadLazyFilter.profile_status = this.profileStatusSearchValue ? this.profileStatusSearchValue : "";
      this.loadLazyFilter.card_status = this.cardStatusSearchValue ? this.cardStatusSearchValue : "";
      this.profileTable.first = 0;
      this.loadLazyFilter.pageNo = 1;
      this.getProfilesByProfileType();
    }, 1000);
  }

  getFormattedDate(date) {

    let d = new Date(date);
    
    if (!isNaN(d.getTime())) {
      return true;
    } else {
      return false;
    }
  }
}
