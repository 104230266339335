import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardmodelInventoryComponent } from './cardmodel-inventory/cardmodel-inventory.component';
import{CardModelRoutes} from './cardmodel.routing';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../../../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
import {ButtonModule} from 'primeng/button';
import { MultiSelectModule, DropdownModule, SliderModule,DialogModule } from 'primeng/primeng';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    SharedModule,
    CardModelRoutes,
    ButtonModule,
    DialogModule,
     FormsModule,  HttpClientModule, BrowserAnimationsModule, MultiSelectModule, DropdownModule, SliderModule

    
  ],
  declarations: [CardmodelInventoryComponent]
})
export class CardmodelModule { }
