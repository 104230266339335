import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CardInventoryComponent } from './card-inventory/card-inventory.component';
import { CardDetailsComponent } from './card-details/card-details.component';
import { OtaComponent } from './card-details/ota/ota/ota.component';
// import { AuthGuard } from '../auth-guard.service';
const routes: Routes = [
	{
		path: '',
		redirectTo: 'card-inventory',
		pathMatch: 'full'
	},
	{ path: 'card-inventory', component: CardInventoryComponent },
	{ path: 'card-inventory/card-details/:id', component: CardDetailsComponent },
	{ path: 'card-inventory/ota', component: OtaComponent }

];

export const CardRoutes: ModuleWithProviders = RouterModule.forChild(routes);