import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileInventoryComponent } from './profile-inventory/profile-inventory.component';
import { TableModule } from 'primeng/table';
import { ProfileRoutes } from './profile.routing';
import { SharedModule } from '../../../shared/shared.module';
import { ProfileUsageComponent } from './profile-inventory/profile-usage/profile-usage.component';


@NgModule({
  imports: [
    CommonModule,
    ProfileRoutes,
    TableModule,
    SharedModule
    
  ],
  declarations: [ProfileInventoryComponent, ProfileUsageComponent]
})
export class ProfileModule { }
