import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./token.interceptor";
import { AppRoutingComponent } from "./app.routing";
import { AuthGuard } from "./auth-guard.service";
import { MainModule } from "./feature/main/main.module";
import { AuthModule } from "./feature/auth/auth.module";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { SharedModule } from "./shared/shared.module";
import { Toaster } from "./shared/services/toaster.service";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { MomentModule } from 'angular2-moment';
import { ModalModule } from 'ngx-bootstrap/modal';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingComponent,
    AuthModule,
    MainModule,
    FormsModule,
    CollapseModule.forRoot(),
    SharedModule,
    MomentModule,
    ModalModule,
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthGuard,
    Toaster,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
