import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { SESSION_TIMEOUT_WARNING } from "src/app/shared/services/common.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-idle-timeout",
  templateUrl: "./idle-timeout.component.html",
  styleUrls: ["./idle-timeout.component.scss"],
})
export class IdleTimeoutComponent {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  title = "angular-idle-timeout";
  public modalRef: BsModalRef;

  @ViewChild("childModal") childModal: ModalDirective;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private modalService: BsModalService,
    private userService: UserService
  ) {
    this.idle.setIdle(SESSION_TIMEOUT_WARNING);
    this.idle.setTimeout(15);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      this.reset();
    });
    this.idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = "Timed out!";
      this.timedOut = true;
      localStorage.removeItem("currentUserToken");
      localStorage.removeItem("loggedInTime");
      this.router.navigate(["/login"]);
      this.userService.setUserLoggedIn(false);
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idle.clearInterrupts();
      this.idleState = "You've gone idle!";
      this.childModal.show();
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
    });
    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
    });
    this.userService.getUserLoggedIn().subscribe((userLoggedIn) => {
      const currentUserToken = localStorage.getItem("currentUserToken");
      if (userLoggedIn && currentUserToken) {
        this.reset();
      } else {
        this.idle.stop();
      }
    });
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.childModal.hide();
    this.reset();
  }

  logout() {
    this.childModal.hide();
    this.userService.setUserLoggedIn(false);
    localStorage.removeItem("currentUserToken");
    localStorage.removeItem("loggedInTime");
    this.router.navigate(["/login"]);
  }
}
