import { Component, OnInit, ViewChild } from "@angular/core";
import { Paginator } from "./transaction-tracker.interface";

import { TransactionService } from "../../../../shared/services/transaction.service";
import * as Constants from "./../../../../shared/services/common.service";
import { OTAService } from "./../../../../shared/services/ota.service";

@Component({
  selector: "app-transaction-tracker",
  templateUrl: "./transaction-tracker.component.html",
  styleUrls: ["./transaction-tracker.component.scss"],
})
export class TransactionTrackerComponent implements OnInit {
  transactions: any[];
  cols: any[];
  isLoading: boolean;
  loadLazyFilter: Paginator;
  iccid: string = "";
  eid: string = "";
  imsi: string = "";
  tid: string = "";
  showInboundNotification: boolean = false;

  @ViewChild("transactionTable") transactionTable: any;

  constructor(
    private transactionService: TransactionService,
    private otaService: OTAService
  ) {
    this.isLoading = false;
    this.cols = [
      { field: "tid", header: "Transaction ID" },
      { field: "transaction_type", header: "Transaction Type" },
      { field: "eid", header: "EID" },
      { field: "iccid", header: "ICCID" },
      { field: "dateCreated", header: "Start Time" },
      { field: "dateModified", header: "End Time" },
      { field: "status", header: "Status" },
      { field: "notes", header: "Notes" },
    ];
    this.loadLazyFilter = {
      pageNo: 1,
      limit: Constants.PAGE_PAGINATION,
      eidSearch: "",
      imsiSearch: "",
      iccidSearch: "",
      sortOrder: "DESC",
      sortColumn: "create_time",
      tidSearch: "",
      showInboundNotification: this.showInboundNotification,
      totalRecords: 0,
    };
  }

  ngOnInit() {
    console.log("Initial Check" + this.showInboundNotification);
  }

  customFunc(data) {
    this.showInboundNotification = data;
    console.log(this.showInboundNotification);
    this.loadLazyFilter.showInboundNotification = this.showInboundNotification;
    this.getTransactions();
  }

  getTransactions() {
    setTimeout(() => {
      this.isLoading = true;
    }, 0);
    this.transactionService
      .getTransactionHistory(this.loadLazyFilter)
      .subscribe((response) => {
        this.isLoading = false;
        this.transactions = response.transactions;
        this.loadLazyFilter.totalRecords = response.totalRecords;
        this.transactions.forEach((transaction: any) => {
          transaction["logStatus"] = [];
          transaction.isLogLoading = true;
          if (this.getFormattedDate(transaction.dateModified)) {
            transaction.dateModified = transaction.dateModified;
          } else {
            transaction.dateModified = "-";
          }
          if (this.getFormattedDate(transaction.dateCreated)) {
            transaction.dateCreated = transaction.dateCreated;
          } else {
            transaction.dateCreated = "-";
          }
          transaction.imsi = transaction.imsi ? transaction.imsis[0].imsi : "-";
          let imsiArray = transaction.imsis;
          imsiArray.sort((a, b) => {
            return a.imsi_index - b.imsi_index;
          });
          transaction.imsi = imsiArray.map((imsi) => {
            let o = {};
            o["value"] = imsi.imsi;
            o["index"] = imsi.imsi_index;
            return o;
          });
        });
      });
  }

  loadTransactionsLazy(event) {
    this.loadLazyFilter.limit = event.rows;
    this.loadLazyFilter.pageNo = event.first / event.rows + 1;
    this.loadLazyFilter.sortColumn = event.sortField;
    this.loadLazyFilter.sortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
    this.loadLazyFilter.showInboundNotification = this.showInboundNotification;
    this.getTransactions();
  }

  search(event, field) {
    setTimeout(() => {
      this.loadLazyFilter.iccidSearch = this.iccid;
      this.loadLazyFilter.eidSearch = this.eid;
      this.loadLazyFilter.imsiSearch = this.imsi;
      this.loadLazyFilter.tidSearch = this.tid;
      this.transactionTable.first = 0;
      this.loadLazyFilter.pageNo = 1;
      this.loadLazyFilter.showInboundNotification =
        this.showInboundNotification;
      this.getTransactions();
    }, 1000);
  }

  moveleft() {
    document.getElementsByClassName(
      "ui-table-scrollable-body"
    )[0].scrollLeft -= 100;
  }
  moveright() {
    document.getElementsByClassName(
      "ui-table-scrollable-body"
    )[0].scrollLeft += 100;
  }

  loadTransactionLogStatus(event, data) {
    this.otaService.checkLogByTID_GET(data.tid).subscribe(
      (response) => {
        data.logStatus = response;
        data.isLogLoading = false;

        data.logStatus.forEach((log) => {
          if (this.getFormattedDate(log.time)) {
            log.time = log.time;
          } else {
            log.time = "-";
          }
        });
      },
      (err) => {
        data.isLogLoading = false;
        // item.reponseParam = { ...item.reponseParam, logBody: [{ 'time': err.error, 'status': err.error, 'action': err.error }] }
      }
    );
  }

  getFormattedDate(date) {
    let d = new Date(date);

    if (!isNaN(d.getTime())) {
      return true;
    } else {
      return false;
    }
  }
}
