import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { Paginator } from "../../feature/main/transaction-tracker/transaction-tracker/transaction-tracker.interface";
import * as Constants from "./../../shared/services/common.service";

@Injectable({
  providedIn: "root",
})
export class TransactionService {
  constructor(private _httpClient: HttpClient) {}

  getTransactionHistory(paginator: Paginator): Observable<any> {
    return this._httpClient.get(
      `${Constants.API_URL}/api/INV.getTransactionHistory?limit=${paginator.limit}&page=${paginator.pageNo}&sort_by=${paginator.sortColumn}&sort_order=${paginator.sortOrder}&eid_search=${paginator.eidSearch}&imsi_search=${paginator.imsiSearch}&iccid_search=${paginator.iccidSearch}&tidSearch=${paginator.tidSearch}&showInboundNotification=${paginator.showInboundNotification}`
    );
  }
}
