import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import * as Constants from "./shared/services/common.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public constructor(
    private titleService: Title,
    private readonly userService: UserService
  ) {
    const currentUserToken = localStorage.getItem("currentUserToken");
    this.titleService.setTitle(Constants.WEBSITE_TITLE);
    if (currentUserToken) {
      setTimeout(() => {
        this.userService.setUserLoggedIn(true);
      }, 100);
    } else {
      this.userService.setUserLoggedIn(false);
    }
  }
}
